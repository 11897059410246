import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment.prod';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public api = environment.apiUrl;

  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(request.url, 'url');
    if(request.url.startsWith('/login') || request.url.startsWith('/accounts/reset')){ 
      request = request.clone({
        //url: 'http://pagnahora.com/web'+request.url
        url: this.api+request.url,
        //url: 'http://localhost:4040/web'+request.url
      });
    } else {
      if(request.url.startsWith('https://')) {
        request = request.clone({
          url:  request.url,
          setHeaders: {
            'x-auth-token': localStorage.getItem("token")
          }
        });
      } else {
        request = request.clone({
          url:  this.api+request.url,
          setHeaders: {
            'x-auth-token': localStorage.getItem("token")
          }
        });
      }
      
    }
    
    console.log(request, 'request');
    return next.handle(request);
  }
}