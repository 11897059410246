import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public token;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private returnUrl: string | null = null; // Armazenar a URL de retorno

    constructor(private httpClient: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    // Métodos para gerenciar a URL de retorno
    setReturnUrl(url: string) {
        this.returnUrl = url;
    }

    getReturnUrl(): string | null {
        return this.returnUrl;
    }

    resetReturnUrl() {
        this.returnUrl = null;
    }

    reset(email: string, rol: string) {
        return this.httpClient.post(`/accounts/reset`, { email, rol });
    }

    login(email: string, password: string, rol: string) {
        return this.httpClient.post(`/login`, { email, password, rol }).pipe(
            map(user => {
                if (user && user['token']) {
                    this.currentUserSubject.next(null);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('token', user['token']);
                    this.currentUserSubject.next(user);
                }
                window.location.reload();
                return user;
            })
        );
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/login']);
    }
}
