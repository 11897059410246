import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { environment } from '../../../../environments/environment.prod';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  public titleHTML: any;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  isMobile: boolean = false;

  themes = [
    { value: 'default', name: 'Light' },
    { value: 'dark', name: 'Dark' },
    { value: 'cosmic', name: 'Cosmic' },
    { value: 'corporate', name: 'Corporate' },
  ];

  currentTheme = 'default';
  userMenu = [{ title: 'Deslogar' }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {
    this.titleHTML = this.sanitizer.bypassSecurityTrustHtml(environment.titleHTML);
  }

  ngOnInit() {
    this.menuService.onItemClick()
      .subscribe(item => {
        if (item.item.title === 'Deslogar') {
          this.authService.logout();
        }
        this.closeSidebar();
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => {
          this.isMobile = currentBreakpoint.width < xl;
          return this.isMobile;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.currentTheme = this.themeService.currentTheme;

    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.user = { 'name': user.email };

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  closeSidebar() {
    if (this.isMobile) {
      this.sidebarService.collapse('menu-sidebar');
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const sidebar = document.querySelector('nb-sidebar.menu-sidebar');
    const targetElement = event.target as HTMLElement;

    if (sidebar && !sidebar.contains(targetElement) && !targetElement.closest('.logo-container')) {
      this.closeSidebar();
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
